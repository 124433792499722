<template>
  <div class="tree-group-icon treeDefault">
    <img src="../../assets/images/group_tree.png">
    <!--              <svg-icon name="group_tree" width="100%" height="100%"></svg-icon>-->
  </div>
</template>

<script>
export default {
  name: "TreeGroupIcon"
}
</script>

<style scoped>
.tree-group-icon {
  background-color: var(--lightBlue);
  overflow: hidden;
  border-radius: 15px;
  flex-shrink: 0;
  --png-size:80%;
}
.tree-group-icon.treeDefault{
  width: calc(var(--userIconSize) - 2px);
  height: calc(var(--userIconSize) - 2px);
  margin:1px;
}

.tree-group-icon img{

  width: var(--png-size);
  height: var(--png-size);

  padding: calc(calc(100% - var(--png-size)) / 2);
}
</style>
