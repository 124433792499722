<template>
  <div id="wrap">
    <div class="container">
      <div class="candidates">
        <div class="search-wrap left-wrap">
          <div class="search-container">
        <span class="search-container-input org-user-input-wrapper">
          <span class="org-user-input-prefix">
            <i class="el-icon-search"></i>
          </span>
          <input class="org-user-input" type="text" :placeholder="searchPlaceholder" maxlength="20">
          <span class="org-user-input-suffix" @click="clearSearchKeyWords">
            <i class="el-icon-circle-close"></i>
          </span>
        </span>
          </div>
          <p class="numberTip" :class="{'number-wrap--scroll':showNumberTopVeil}">{{tool_title ? tool_title : '部门及成员'}}</p>
        </div>
        <div class="number-wrap left-wrap"
             @scroll="$event.target.scrollTop < 10 ?
             showNumberTopVeil = false
             : showNumberTopVeil = true ">
          <div class="organizationTree">
            <el-tree
                :data="organizations"
                ref="organizationSelectionTree"
                show-checkbox
                :check-strictly="true"
                :expand-on-click-node="false"
                node-key="id"
                :default-expanded-keys="defaultExpandedKeys"
                :auto-expand-parent="false"
                :default-checked-keys="selected_organizations"
                :empty-text="organizationEmptyText"
                @check="treeCheck">
              <template #default="{ node, data }">
                <span class="custom-tree-node">
                  <template v-if="!data.userName">
                    <tree-group-icon @click="proxyCheckLabel($event)"></tree-group-icon>
                    <span class="tree-node-label" @click="proxyCheckLabel($event)">{{ node.label }}</span>

                    <span class="tree-node-expand" @click="proxyExpendLabel($event)"
                          :style="{visibility:data.children.length ? 'visible':'hidden'}"
                    ><svg-icon width="100%" height="100%" name="arrow-left"></svg-icon></span>
                    <el-dropdown class="tree-node-dropdown" v-if="showPermission" trigger="click">
                      <span class="user-permission-span" :data-org="data.id"><i class="el-icon-menu"></i></span>
                      <template #dropdown>
                        <el-dropdown-menu>
                          <div class="permission-checkbox-container">
                            <el-checkbox :disabled="manager_delete_disable&&node.label=='模板审批组'" @change="organizationPermissionChanged(data.id, 1)" :checked="organizationPermission[data.id + ''] === null || organizationPermission[node.id + ''] === undefined || (organizationPermission[node.id + ''] & 1) === 1">可查看</el-checkbox>
                          </div>
                          <div class="permission-checkbox-container">
                            <el-checkbox :disabled="manager_delete_disable&&node.label=='模板审批组'" @change="organizationPermissionChanged(data.id, 2)" :checked="(organizationPermission[data.id + ''] & 2) === 2">可修改</el-checkbox>
                          </div>
                        </el-dropdown-menu>
                      </template>
                    </el-dropdown>
                  </template>
                  <template v-else>
                    <user-icon @click="proxyCheckLabel($event)" :url="data.headImgUrl" :name="data.userName" :uid="data.userName"></user-icon>
                    <span class="tree-node-label" @click="proxyCheckLabel($event)">{{ data.userName }}</span>
                    <el-dropdown v-if="showPermission" trigger="click">
                      <span class="user-permission-span" :data-uid="data.uid"><i class="el-icon-menu"></i></span>
                      <template #dropdown>
                        <el-dropdown-menu>
                          <div class="permission-checkbox-container">
                            <el-checkbox  @change="userPermissionChanged(data.uid, 1)" :checked="userPermission[data.uid] === null || userPermission[data.uid] === undefined || (userPermission[data.uid] & 1) === 1">可查看</el-checkbox>
                          </div>
                          <div class="permission-checkbox-container">
                            <el-checkbox @change="userPermissionChanged(data.uid, 2)" :checked="(userPermission[data.uid] & 2) === 2">可修改</el-checkbox>
                          </div>
                        </el-dropdown-menu>
                      </template>
                    </el-dropdown>
                  </template>

                </span>
              </template>
            </el-tree>
          </div>
        </div>

      </div>
      <el-divider v-if="showSelected === true" direction="vertical" class="candidatesSelectedDivider"></el-divider>
      <div v-if="showSelected === true" class="selected">
        <div class="selected-tip" style="margin-bottom: 10px">已选：{{selectedObjects.length}}个</div>
        <div v-for="(o, index) in selectedObjects" class="selected-row">
          <div class="selected-row-left">
            <div v-if="o.type === 'user'" class="selected-obj-img" :class="{treePerson:!o.headImgUrl}">
              <user-icon :url="o.headImgUrl" :name="o.objectName" :uid="o.objectName"></user-icon>
            </div>
            <tree-group-icon v-else></tree-group-icon>

            <el-tooltip
            :content="o.objectName"
            placement="left"
            >
              <span class="selected-row-object-name">{{o.objectName}}</span>
            </el-tooltip>
          </div>

          <div v-if="!(manager_delete_disable&&o.objectName=='模板审批组')" class="selected-row-remove" @click="onSelectionRowClicked(o.type, o.objectId, index)" :o-type="o.type" :o-id="o.objectId"><i class="el-icon el-icon-close"></i></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../components/Header";
import {ElLoading, ElMessage} from 'element-plus';
import config, {DEPLOY_TYPE} from "../config";
import {getTeamOrganizations, getTeamUser, getTeamInfo} from "../api/api";
import SvgIcon from "./SvgIcon/svgIcon";
import{colorLog,debounce} from '../util'
import BubbleDialog from "./dialog/BubbleDialog";
import UserIcon from "./UserIcon";
import TreeGroupIcon from "./universalUI/TreeGroupIcon";

export default {
  name: "organization-user-selection",
  components: {TreeGroupIcon, UserIcon, SvgIcon},
  props: [
    'select_organization',
    'select_user',
    'show_selected',
    'dom_selector',
    'selected_users',
    'selected_organizations',
    'show_permission',
    'organization_permission',
    'user_permission',
    'tool_title',
    'manager_delete_disable'

  ],
  data: function () {
    return {
      userInfo: JSON.parse(sessionStorage.getItem("userInfo")),
      selectOrganization: this.select_organization || true,
      selectUser: this.select_user || true,
      showSelected: this.show_selected || true,
      initialSelectedUsers: this.selected_users || [],
      initialSelectedOrganizations: this.selected_organizations || [],
      organizationPermission: this.organization_permission ? JSON.parse(JSON.stringify(this.organization_permission)) : {},
      userPermission: this.user_permission ? JSON.parse(JSON.stringify(this.user_permission)) : {},
      showPermission: this.show_permission || false,
      renderedOrganizationIds: {},
      unAssignedOrganizations:{},
      organizations: [], //organizations里面加上user的数据 3/08
      organizationAddedIdList: [],
      originOrganizations: [],
      originFormattedOrganizations: [],
      selectedNodeKeys:[],
      users: [],
      originUsers: [],
      selectedObjects: [],
      defaultExpandedKeys:[],
      teamId: '',
      teamName:'',
      rootOrganizationId:'',
      clickedOrganization: null,
      spelling: false,
      searchIng: false,
      waitingSearchWords: null,
      currentSearchWords: null,
      searchTimer: null,
      searchPlaceholder: '',
      organizationEmptyText: '',
      userEmptyText: '',

      //用于class的布尔
      showNumberTopVeil:false,

      isFilterState:false, //过滤时为true
    };
  },
  mounted: async function () {
    this.teamId = this.userInfo.mainSiteTeamId;

    try{
      this.teamName = (await getTeamInfo(this.teamId)).data.data.teamName;
    }catch (e){
      colorLog.orange('获取teamName失败',e);
    }

    this.setSearchPlaceholder();

    //这个数组保存promise类型元素,当组织和成员都http请求获取并渲染完毕时,将树形结构初始化选中的部门人员渲染到右侧已选中框
    const promiseList = [];
    if (this.selectOrganization === true) {
      let proxyResolve = null;
      promiseList.push(new Promise((resolve)=>{
        proxyResolve = resolve;
      }))

      getTeamOrganizations(this.teamId).then(res => {
        if (res.data.code === 0) {
          this.originOrganizations = res.data.data;

          //测试环境伪数据
          if(config.deployType == DEPLOY_TYPE.TEST && !this.teamName){
            this.originOrganizations = this.originOrganizations.concat([
              {
                "id": 430,
                "name": "法务部01",
                "parentId": 429,
                "teamId": "5e7db45e58fb5b34e945e7b0"
              },
              {
                "id": 431,
                "name": "法务部02",
                "parentId": 429,
                "teamId": "5e7db45e58fb5b34e945e7b0"
              },
              {
                "id": 353,
                "name": "test环境团队所有人",
                "parentId": null,
                "teamId": "5e7db45e58fb5b34e945e7b0"
              },
              {
                "id": 359,
                "name": "123123",
                "parentId": 353,
                "teamId": "5e7db45e58fb5b34e945e7b0"
              },
              {
                "id": 360,
                "name": "1231231231313",
                "parentId": 353,
                "teamId": "5e7db45e58fb5b34e945e7b0"
              },
              {
                "id": 435,
                "name": "1号",
                "parentId": 353,
                "teamId": "5e7db45e58fb5b34e945e7b0"
              },
              {
                "id": 432,
                "name": "23123",
                "parentId": 353,
                "teamId": "5e7db45e58fb5b34e945e7b0"
              },
              {
                "id": 413,
                "name": "Axx",
                "parentId": 353,
                "teamId": "5e7db45e58fb5b34e945e7b0"
              },
              {
                "id": 404,
                "name": "cd1号",
                "parentId": 353,
                "teamId": "5e7db45e58fb5b34e945e7b0"
              },
              {
                "id": 405,
                "name": "cd一号",
                "parentId": 353,
                "teamId": "5e7db45e58fb5b34e945e7b0"
              },
              {
                "id": 399,
                "name": "公关部",
                "parentId": 353,
                "teamId": "5e7db45e58fb5b34e945e7b0"
              },
              {
                "id": 429,
                "name": "法务部",
                "parentId": 353,
                "teamId": "5e7db45e58fb5b34e945e7b0"
              }
            ]);
            this.teamName = 'test环境团队';
          }

          //处理originOrganizations数据里面的name:xxx所有人
          let specialOrganization = this.originOrganizations.find(item => item.name === this.teamName + '所有人');
          this.originOrganizations.forEach(item => {
            //让'xxx所有人'不默认展开
            if(item.id !== specialOrganization.id){
              this.defaultExpandedKeys.push(item.id);
            }

            //删去parentId让'xxx所有人'的子部分提到和它同一级
            if(item.parentId == specialOrganization.id){
              item.parentId = null;
            }
          })

          this.selectedNodeKeys = [...this.initialSelectedOrganizations];
          this.renderOrganizations(JSON.parse(JSON.stringify(this.originOrganizations)),true);

          proxyResolve();
        }
      }).catch(error => {
        console.error('get organizations  error === ', error);
      })
    }

    if (this.selectUser === true) {
      let proxyResolve = null;
      promiseList.push(new Promise((resolve)=>{
        proxyResolve = resolve;
      }))

      getTeamUser(this.teamId).then(res => {
        if (res.data.code === 0){
          this.originUsers = res.data.data;
          this.renderUsers(JSON.parse(JSON.stringify(this.originUsers)),true);
          proxyResolve();
        }else {
          ElMessage.error("加载团队成员失败")
        }
      }).catch(error => {
        console.error('get user  error === ', error);
      })
    }

    this.bindSearchInputEvent();

    //将树形结构初始化选中的部门人员渲染到右侧已选中框
    Promise.all(promiseList).then(()=>{
      //合并users和organization数据,user数据都放在xxx所有人下
      let specialOrganization = this.organizations.find(item => {
        return item.label === this.teamName + '所有人';
      });
      try{
        specialOrganization.children = this.users;
      }catch (e){
        colorLog.orange('未能将users放入xxx所有人下,将自动放在根节点上',e)
        this.organizations = this.organizations.concat(this.users);
      }
      this.originFormattedOrganizations = JSON.parse(JSON.stringify(this.organizations));

      this.$nextTick(()=>{
        this.users.forEach(user => {
          if(this.initialSelectedUsers.indexOf(user.uid) > -1){
            this.selectedNodeKeys.push(user.id);
            this.$refs.organizationSelectionTree.setChecked(user.id,true,false);
          }
        })
      })
      this.setSelectedObjects();
    })
  },
  methods: {
    setSearchPlaceholder: function () {
      if (this.selectOrganization === true && this.selectUser === true) {
        this.searchPlaceholder = '请输入部门名或成员名';
      } else if (this.selectOrganization === true) {
        this.searchPlaceholder = '请输入部门名';
      } else if (this.selectUser === true) {
        this.searchPlaceholder = '请输入成员名';
      }
    },
    bindSearchInputEvent: function () {
      const searchContainer = document.querySelector('.search-container');
      const searchInput = document.querySelector('.org-user-input');
      searchInput.addEventListener('focus', () => {
        searchContainer.classList.add('search-container-active');
      });

      searchInput.addEventListener('blur', () => {
        searchContainer.classList.remove('search-container-active');
      });

      searchInput.addEventListener("keyup",  () => {
        let searchWords = searchInput.value;
        this.filterOrganizationsAndUsers(searchWords);
      })
    },
    filterOrganizationsAndUsers: function (searchWords) {
      if (this.currentSearchWords === searchWords) {
        return;
      }
      if (this.searchIng) {
        this.waitingSearchWords = searchWords;
        return;
      }
      if(this.searchTimer != null){
        clearTimeout(this.searchTimer);
        this.searchTimer = null;
      }
      this.searchTimer = setTimeout(() => {
        this.doFilterData(searchWords);
      }, 200);
    },
    //搜索调用的过滤方法
    doFilterData: function (searchWords) {
      if(searchWords.length == 0){
        this.isFilterState = false;
      }else{
        this.isFilterState = true;
      }

      this.searchIng = true;
      this.currentSearchWords = searchWords;
      this.showOrganizations = [];
      this.filterData(JSON.parse(JSON.stringify(this.originFormattedOrganizations)));
      this.$nextTick(()=>{
        this.$refs.organizationSelectionTree.setCheckedKeys(this.selectedNodeKeys);
      })
      this.searchIng = false;
      this.currentSearchWords= null;
      if (this.waitingSearchWords != null) {
        const waitingSearchWords = this.waitingSearchWords;
        this.waitingSearchWords = null;
        this.doFilterData(waitingSearchWords);
      }

      this.organizationEmptyText = '';
      this.userEmptyText = '';
      if (this.selectOrganization === true && this.selectUser === true && this.organizations.length === 0 && this.users.length === 0) {
        this.organizationEmptyText = '未找到部门或成员';
      } else if (this.selectOrganization === true && this.organizations.length === 0) {
        this.organizationEmptyText = '未找到部门';
      } else if (this.selectUser === true && this.users.length === 0) {
        this.userEmptyText = '未找到成员';
      }
    },
    clearSearchKeyWords: function() {
      const searchInput = document.querySelector('.org-user-input');
      searchInput.value = '';
      this.doFilterData('');
    },
    //这个逻辑只会在组件生命中执行一次
    renderUsers: function(users) {
      this.users = [];

      for (let index in users) {
        const user = users[index];
        user.key = this.uuid();
        //!!这里将user原来的id给覆盖了
        user.id = 'u_' + user.uid;
        user['label'] = user.userName;
        if (this.currentSearchWords === null || user.userName.indexOf(this.currentSearchWords) >= 0) {
          this.users.push(user);
        }
      }
    },
    renderOrganizations: function (orgs) {
      this.renderedOrganizationIds = {};
      this.unAssignedOrganizations = {};
      this.organizations = [];
      for (const org of orgs) {
        if (this.currentSearchWords === null || org.name.indexOf(this.currentSearchWords) >= 0) {
          this.pushOrganization(org, this.organizations);
        }
      }
    },
    filterData(orgs){
      this.organizations = [];

      const find = (orgs) => {
        orgs.forEach(org=>{
          if(org.label.indexOf(this.currentSearchWords) >= 0){
            this.organizations.push(org);
          }else if(org.children && org.children.length > 0){
            find(org.children);
          }
        })
      }

      find(orgs);
    },
    /**
     * 用于处理'组织'这样的树形结构数据
     * */
    pushOrganization: function(org, target) {
      if(!org.visible) return;
      //从保存已经渲染过的对象看是否有key为parentId的属性
      if(org.parentId){
        //有parentId,那去查看已渲染对象中是否已经保存了父对象
        let parentOrg = this.renderedOrganizationIds[org.parentId];
        if(parentOrg){
          //有则说明target里面有属于org的父对象
          // 记录访问的到父对象的路径
          const reference = [parentOrg.id];
          while(parentOrg.parentId){
            reference.push(parentOrg.parentId);
            parentOrg = this.renderedOrganizationIds[parentOrg.parentId];
          }

          const firstPath = reference.pop();
          let tempOrg = target.find(item => item.id == firstPath);
          //将所有路径拿到后,一步一步地访问到父对象
          while(reference.length){
            let path = reference.pop();
            tempOrg = tempOrg.children.find(item => item.id == path);
          }

          //构造一个特殊类型对象,这个是给组件用的对象,放到父对象children属性中去
          tempOrg.children.push({
            id:org.id,
            label:org.name,
            children:[]
          });

          //成功加到了target中,在已渲染对象里面加上自己
          this.renderedOrganizationIds[org.id] = org;
          //将自己从未分配对象中抽掉
          delete this.unAssignedOrganizations[org.id];
        }else{
          //没有找到父对象,则将它放到未分配对象中
          this.unAssignedOrganizations[org.id] = org;
        }
      }else{
        //没有parentId说明它是顶层
        target.push({
          id:org.id,
          label:org.name,
          children:[]
        })

        //成功加到了target中,在已渲染对象里面加上自己
        this.renderedOrganizationIds[org.id] = org;

        //当它完成了在target顶层加入自己,查看未分配对象数组是否保存了没有被分配的org
        if(Object.keys(this.unAssignedOrganizations).length){
          for(const _org of Object.values(this.unAssignedOrganizations)){
            //_org只会走到pushOrganization的if(org.parentId){...}里面去
            this.pushOrganization(_org,target);
          }
        }
      }
    },
    /**
     * 更新data的selectedNodeKeys属性
     * 这个属性更新逻辑有点绞,单独做一个方法方便以后维护
     * */
    updateSelectedNodeKeys(changeId,selection){
      if(!this.isFilterState){
        //如果不是在搜索过滤的状态,说明这时数据是全面的,可以直接赋值
        this.selectedNodeKeys = selection.checkedKeys;
      }else{
        //数据不是全面的
        if(!selection.checkedKeys.includes(changeId)){
          //不包含在已选checkedKeys数组,说明这个id是取消选择的id
          //在数组里删掉这个值
          this.selectedNodeKeys = this.selectedNodeKeys.filter(item => item != changeId);
        }else{
          this.selectedNodeKeys.push(changeId);
        }
      }
    },
    treeCheck: function (data, selection) {
      this.updateSelectedNodeKeys(data.id,selection);
      //做一个包含判断,如果selection中没有当前触发事件checkbox的id(data.id),就从选中的成员数组删掉它.
      if(!selection.checkedKeys.includes(data.id)){
        this.selectedObjects = this.selectedObjects.filter(item => item.objectId !== data.id);
      }
      this.setSelectedObjects();
    },
    setSelectedObjects: function () {
      this.$nextTick(() => {
        const newSelectedObjects = JSON.parse(JSON.stringify(this.selectedObjects));
        console.log('setSelectedObjects this.$refs === ', this.$refs);
        const selectedOrganizations = this.$refs.organizationSelectionTree.getCheckedNodes();
        selectedOrganizations.forEach((o) => {
          if(newSelectedObjects.findIndex(item => item.objectId == o.id) === -1){
            //没有则往里加
            newSelectedObjects.push({
              type: o.userName ? 'user' : 'organization',
              objectId: o.id,
              objectName: o.label,
              headImgUrl: o.userName ? o.headImgUrl : '',
              email: o.email ? o.email : '',
              phone: o.phone ? o.phone : '',
            });
          }
        })


        this.selectedObjects = newSelectedObjects;
      });
    },
    getSelectedObjects: function () {
      for (const o of this.selectedObjects) {
        if (o.type === 'user') {
          //把再renderUsers方法里id加的'u_'去掉
          o['objectId'] = o['objectId'].replace(/^u_/,'');
          o['permission'] = this.userPermission[o.objectId] || 1;
        } else if (o.type === 'organization') {
          o['permission'] = this.organizationPermission[o.objectId + ''] || 1;
        }
      }
      return this.selectedObjects;
    },
    findUserByUid: function (uid) {
      let user = null;
      for (let i = 0; i < this.users.length; i++) {
        if (this.users[i]['uid'] === uid) {
          user = this.users[i];
          break;
        }
      }
      return user;
    },
    getSelectedUserIds: function () {
      // 取选中的userId  存在一个map里面方便使用
      const ids = {};
      const dom = this.dom_selector ? document.querySelector(this.dom_selector) : document;
      //拿到已选数组中为类型为user的数据
      const selectObjectsUsers = this.selectedObjects.filter(item => item.type == 'user');
      //存到ids里
      selectObjectsUsers.forEach(user =>{
        ids[user.objectId] = true;
      });

      const selectedDomLabels = dom.querySelectorAll('.user-list .el-checkbox.is-checked');
      selectedDomLabels.forEach((ele) => {
        const checkbox = ele.querySelector('.el-checkbox__original');
        const uid = checkbox.getAttribute('value');
        const user = this.findUserByUid(uid);
        if (user !== null) {
          ids[user.uid] = true;
        } else {
          console.error('findUserByUid  error!  uid:' + uid);
        }
      })

      return ids;
    },
    onSelectionRowClicked: function (type, id, index) {
      const selectedNodes = this.$refs.organizationSelectionTree.getCheckedNodes();
      const newSelectedNodes = [];
      for (const node of selectedNodes) {
        if (node.id !== id) {
          newSelectedNodes.push(node);
        }
      }
      this.selectedNodeKeys = this.selectedNodeKeys.filter(item => item != id);
      this.$refs.organizationSelectionTree.setCheckedNodes(newSelectedNodes);


      this.selectedObjects.splice(index, 1);
    },
    organizationPermissionChanged: function (organizationId, permissionType) {
      if (this.organizationPermission[organizationId] === null || this.organizationPermission[organizationId] === undefined) {
        this.organizationPermission[organizationId] = 1;
      }
      const hasPermission = this.organizationPermission[organizationId] & permissionType;
      if (hasPermission === 0) {
        this.organizationPermission[organizationId] += permissionType;
      } else {
        this.organizationPermission[organizationId] -= permissionType;
      }
    },
    userPermissionChanged: function (uid, permissionType) {
      if (this.userPermission[uid] === null || this.userPermission[uid] === undefined) {
        this.userPermission[uid] = 1;
      }
      const hasPermission = this.userPermission[uid] & permissionType;
      if (hasPermission === 0) {
        this.userPermission[uid] += permissionType;
      } else {
        this.userPermission[uid] -= permissionType;
      }
    },
    uuid: function() {
      const s = [];
      const hexDigits = "0123456789abcdef";
      for (let i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
      }
      s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
      s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
      s[8] = s[13] = s[18] = s[23] = "-";

      return s.join("");
    },
    /**
     * 这个方法是传递click事件到elementUI的默认dom上
     * @param e
     */
    proxyExpendLabel(e){
      e.target.closest('.el-tree-node__content').querySelector('.el-tree-node__expand-icon').click()
    },
    proxyCheckLabel(e){
      e.target.closest('.el-tree-node__content').querySelector('.el-checkbox').click();
    }
  },
}
</script>

<style scoped>
  #wrap{
    height: 100%;
    position: relative;
  }
  .container {
    display: flex;
    flex-direction: row;
    text-align: left;
    padding: 20px 20px;
    height: 100%;
    justify-content: center;
    align-items: center;
  }

  .candidates {
    width: 300px;
    height: 100%;

  }
  /* scrollbar */
  ::-webkit-scrollbar-track-piece {
    background-color: #f8f8f8;
  }

  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    display: block;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #dddddd;
    background-clip: padding-box;
    min-height: 28px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #bbb;
  }

  .candidatesSelectedDivider {
      height: 100%;
  }

  /*
  设置变量
   */
  .container{
    --treeRowHeight:31px;
    --treeRowIconRightMargin:10px;
    box-sizing: border-box;
  }
  /*
  设置变量
   */

  .selected {
    width: 300px;
    height: 100%;
    overflow-y: auto;
    /*margin-left: 60px;*/
  }

  .selected-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: var(--treeRowHeight);
    line-height: var(--treeRowHeight);
    padding-right: 5px;
  }



  .selected-row button {
    border: unset;
    margin-right: 50px;
  }

  .selected-row-remove {
    cursor: pointer;
  }



  .user-permission-span {
    cursor: pointer;
  }

  .permission-checkbox-container {
    line-height: 36px;
    padding: 0 20px;
  }

  .search-wrap{

  }

  .search-container {
    margin-bottom: 10px;
    margin-right: 16px;
    border-radius: 16px;
    border: 1px solid #dee0e3;
  }

  .search-container-active {
    border-color: #40a9ff
  }

  .org-user-input-wrapper {
    padding: 0 10px;
    height: 32px;
    border-radius: 2px;
    box-sizing: border-box;
    margin: 0;
    color: rgba(0,0,0,.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    font-feature-settings: "tnum";
    position: relative;
    display: inline-block;
    width: 100%;
    text-align: start;
  }

  .org-user-input-prefix {
    left: 14px;
    width: 16px;
    height: 16px;
    line-height: 16px;
    color: #b7bec7;
    font-size: 16px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .org-user-input {
    padding-left: 30px;
    padding-right: 10px;
    height: 32px;
    border: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    background-color: #fff;
    background-image: none;
    box-sizing: border-box;
    margin: 0;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: "tnum";
    border-radius: 4px;
    transition: all .3s;
  }

  .org-user-input:focus {
    outline: 0;
    border: none;
    box-shadow: none;
  }

  .org-user-input:focus, .org-user-input:hover {
    border-color: #40a9ff;
    border-right-width: 1px!important;
  }

  .org-user-input-suffix {
    position: absolute;
    top: 50%;
    z-index: 2;
    color: rgba(0,0,0,.65);
    line-height: 0;
    transform: translateY(-50%);
    font-size: 16px;
    color: #646a73;
    right: 10px;
    cursor: pointer;
  }
</style>
<style scoped>

.organizationTree >>> .el-dropdown {

}

.user-list .el-dropdown {

}

.user-list .el-checkbox {
  line-height: 20px;
  height: 20px;
  display: flex;
  align-items: center;
}

.user-checkbox-container .el-checkbox__label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}
.selected-row-object-name:hover{
  background: #cccccc73;
}
.selected-row-object-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /*max-width: 150px;*/
  margin-left: var(--treeRowIconRightMargin);
  cursor: pointer;
  display: inline-block;
  width: calc(100% - var(--userIconSize) - var(--treeRowIconRightMargin) - 14px );
}
.numberTip{
  margin:0;
  font-size: 14px;
  color: #666;
  position: relative;
}
.number-wrap{
  scroll-width:thin;
  height: calc(100% - 64px);
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;

}

.user-checkbox-container {
  display: flex;
  align-items: center;
  line-height: var(--treeRowHeight);
  position: relative;
  height: var(--treeRowHeight);
  padding-right: 5px;
}

.numberTip::before{
  position: absolute;
  content:'';
  bottom: -39px;
  left:0;
  height: 40px;
  width:100%;
  transition: .5s opacity;
  background-image: linear-gradient(to bottom,#fff,transparent);
  z-index: 5;
  opacity: 0;
  pointer-events: none;
}
.number-wrap--scroll::before{
  opacity: 1;
}

.el-tree-node__content{
  height: var(--treeRowHeight);
}
.tree-node-img{
  display: inline-block;
  vertical-align: middle;
  height: var(--userIconSize);
  width: var(--userIconSize);
  border-radius: 15px;
  overflow: hidden;
}
.selected-tip{
  position: sticky;
  top: 0;
  background: #fff;
  z-index:1;
}
.selected-row-left{
  display: flex;
  align-items: center;
  width: calc(100% - 14px);
}
.tree-node-img img{
  height: 100%;
  width: 100%;
}
.tree-node-dropdown{
  top: 50%;
  transform: translateY(-50%);
}
.tree-node-expand{
  display: inline-block;
  width: 14px;
  height: 14px;
  padding:5px;
  transition: .5s transform,.5s color;
  transform: rotate(-180deg);
}
.tree-node-expand:hover{
  color: #5280FF;
}
.tree-node-expand .svg-icon{
  vertical-align: top;
}

.tree-node-label,.tree-userName{
  flex-grow: 1;
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
  display: inline-block;
  vertical-align: middle;
  padding-left: 10px;
  box-sizing: border-box;
}
.tree-userName{
  width: calc(100% - var(--userIconSize) - 10px);
}
.organizationTree >>> .el-tree-node__expand-icon{
  display: none;
}
.organizationTree >>> .el-tree-node__content{
    height: 31px;
}
.custom-tree-node{
  display: flex;
  height: var(--userIconSize);
  width: 0;
  flex-grow: 1;
  align-items: center;
  position: relative;
  padding-right: 5px;
  box-sizing: border-box;
}
.organizationTree .el-tree-node__expand-icon.expanded~.custom-tree-node .tree-node-expand{
  transform: rotate(-90deg);
}
.el-tree-node__content>label.el-checkbox{
  margin-right: 10px;
}
.el-checkbox__input{
 height: 14px;
}
.left-wrap{
  width: 100%;
}
.user-list >>> .el-checkbox{
  width: 100%;
}

.user-list >>> .el-checkbox__label{
  width:0;
  flex-grow: 1;
}

</style>
